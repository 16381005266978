.slider-home {
    flex: 0 0 73% !important;
    max-width: 73% !important;
    overflow: hidden !important;
    padding-bottom: 100px !important;

}

#about .r-office-tcom .col-right .tcom-image-home {
    margin-right: 352px !important;
    margin-left: 17px !important;
}

#home .r-office-tcom .col-right .tcom-image-home {
    margin-right: 365px !important;
    margin-left: 10px !important;
}

#about .r-office-tcom .col-right .tcom-image-home .slick-slide {

    transform: scale(0.95) !important;

}

#about .r-office-tcom .col-right .tcom-image-home .slick-slide>div {
    display: flex;
    align-items: center;
    width: 100%;
}



.container-fluids {
    width: 1740px !important;
    max-width: 100% !important;
}

.ant-message-success>span {
    color: #000;
}